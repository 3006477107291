.logoGB{


    z-index: 100;
    width: 10rem;
    margin: 2rem;

    cursor: pointer;
    position: fixed;

    /* display: flex; */

    
}

.logoGBimg{

    width: 100%;


}