.bannerhome{

    background-color: rgb(179, 178, 178);
    /* width: 100%; */
    /* height: 30rem; */
    /* margin: 0px; */
    /* text-align: center; */
    /* justify-items: center; */
    /* padding: 2rem; */
    z-index: 0;

}

.bannercont{

    /* width: 100%; */
    /* z-index: -9; */
    
}


.startnowBtCont{

    display: flex;
    width: 100%;
    justify-content: center;

}



.startnowBt{

    background-color: #EFCC00;
    color: white;
    width: 6rem;
    text-align: center;
    padding: .8rem;
    border-radius: .4rem;
    margin: .4rem;
    cursor: pointer;
    border: 1px solid transparent;
    z-index: 100000;
    position: absolute;
    bottom: 2rem;
    
    font-weight: 500;


}

.startnowBt:hover{
    /* border: 1px solid grey; */
    background-color: black;
    color: black;
    transition: ;
    transform: ;
    transition-delay: 42ms;

}


.startnowBt a{
    text-decoration: none;
    color: black;

}


.startnowBt:hover a{
    color: white;

}




@media (width <= 1600px) {


    .headhome{
        font-size: 3.2rem;
        font-family:' Montserrat ', sans-serif ;
        color: black;
        font-weight: 600;
        margin-top: 36vh;
        margin-left: 8%;

        margin-right: 8%;
        /* margin-right: auto; */
        z-index: 1;
        position: absolute;
        text-align: center;
        padding: 2rem;
        background-color: #EFCC00;
        border-radius: .4rem;
    
    
    }


    .bannerimg{
        /* width: 100%; */
        /* margin-left: auto;
        margin-right:auto; */
        /* justify-items: center; */
        margin-top: -15rem;
        

    }

    .bannerimgcont{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
    
        /* justify-items: center; */
        overflow: hidden;
        /* top: 0; */
        /* left: 0; */
        /* position: fixed; */
        z-index: 0;
    
    
    }

}


@media (width >= 1600px) {

.headhome{
    
    font-size: 4.2rem;
    color: black;
    font-family:' Montserrat ', sans-serif ;
    color: black;
    font-weight: 600;


    margin-top: 36vh;
    margin-left: 20%;

    margin-right: 20%;
    
    z-index: 1;
    position: absolute;
    text-align: center;
    padding: 4rem;
    background-color: #EFCC00;
    border-radius: .8rem;

    
}
   
    .bannerimg{

        /* height: auto; */
        /* width: 100%; */
        margin-top: -15rem;
        /* margin: 0 auto; */
    }

    .bannerimgcont{
        /* width: 100%; */
        height: 100vh;
        /* display: flex; */
        justify-content: center;
    
        /* justify-items: center; */
        overflow: hidden;
        /* top: 0; */
        /* left: 0; */
        /* position: fixed; */
        z-index: 0;
    
    
    }


}